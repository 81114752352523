<template>
  <div class="add-account">
    <div class="add-account__title">
      {{$t('add_account.title')}}
    </div>
    <div class="add-account__content">
      <div v-if="!showInput" class="add-account__choice">
        <div class="add-account__choice-list">
          <label class="add-account__choice-item">
            <input type="radio" v-model="type" value="exist">
            <span>
              {{$t('add_account.registered')}}
            </span>
          </label>
          <label class="add-account__choice-item">
            <input type="radio" v-model="type" value="new">
            <span>
              {{$t('add_account.new')}}
            </span>
          </label>
        </div>
        <div class="add-account__buttons">
          <ButtonElement :text="$('button_props.append')" @click-button="showInput = true" />
          <div @click="cancel">{{$t('cancel')}}</div>
        </div>
      </div>
      <div v-if="showInput" class="add-account__email">
        <div
          class="form-input"
          :class="
            [
              {'form-input--full' : accountName},
              {'form-input--error' : error }
            ]"
        >
          <input v-model="accountName" type="text" required>
          <span class="form-input__label">
                {{$t('add_account.name_mytarget')}}
              </span>
          <div v-if="errors" class="form-input__error">
            <p v-for="(err, index) in errors" :key="index">
              <span v-for="(err2, index2) in err" :key="index2">
                {{ err2 }}
              </span>
            </p>
          </div>
        </div>
        <ButtonElement
            class="ad-page__no-data-send"
            view="empty"
            :text="type === 'exist' ? $t('button_props.send') : $t('button_props.create_account')"
            @click-button="addAccount(true)"
        />
        <div class="ad-page__no-data-back" @click="showInput = false">
          {{$t('back')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddAccount',
  components: {
    ButtonElement: () => import('../elements/ButtonElement'),
  },
  data() {
    return {
      type: null,
      showInput: false,
      accountName: '',
      error: '',
      errors: null,
    }
  },
  methods: {
    cancel() {
      this.$store.dispatch('Dialog/closeDialog')
    },
  }
}
</script>

<style scoped lang="scss">
  .add-account {
    &__title {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: #000;
      margin-bottom: 2.5rem;
    }

    &__choice {
      &-list {
        margin-bottom: 1rem;
      }

      &-item {
        display: block;
        margin-bottom: 2rem;
        font-size: 2rem;
        line-height: 2.3rem;
        color: #333;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      .button {
        width: 17rem;
        margin-right: 2rem;
      }

      &>div {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: #395FE4;
        cursor: pointer;
      }
    }
  }
</style>
